/* eslint-disable max-len */
import React from 'react'

import * as S from './style'
import { IDetails } from '../../../fundosListados/types'

interface IHeroProps {
  data: {
    name: string;
    description?: string;
    nomeFundo: string;
  };
  backgroundImage: {
    url: string;
    name: string;
  };
  details: IDetails;
}

const Hero = ({ data, details, backgroundImage }: IHeroProps) => {
  const { aplicacaoInicial, saldoMinimo, saldoMinimoPermanencia, taxaAdministracao }: IDetails = details
  const cardDetailsExist = Boolean(aplicacaoInicial && saldoMinimo && saldoMinimoPermanencia && taxaAdministracao)

  return (
    <S.HeroSection className=''>
      <S.BackgroundDiv backgroundUrl={backgroundImage} $cardDetailsExist={cardDetailsExist} />
      <div className='container'>
        <div className='row'>
          <div className='col-12 header-text-block mb-5'>
            <h1 className='f-sora fs-32 lh-40 fs-md-48 lh-md-60 fs-lg-64 lh-lg-80 text-white fw-600'>
              {data.nomeFundo}
            </h1>
            <h2 className='fs-12 lh-15 fs-md-16 lh-md-20 text-gray-400 fw-400 mb-0'>
              {data.description}
            </h2>
          </div>
          <div className='col col-lg-12 card-position d-flex flex-wrap'>
            {cardDetailsExist &&
              <S.Card>
                <span className='fs-12 lh-15 fs-xl-16 lh-xl-20 fw-400 f-inter text-gray-650 d-block'>
                  Aplicação Inicial Mínima
                </span>
                <span className='fs-18 lh-22 fs-md-20 lh-md-25 fs-xl-32 lh-xl-40 f-sora fw-600 text-gray-750'>{aplicacaoInicial.value}</span>
                <span className='fs-12 lh-15 fs-xl-14 lh-xl-17 fw-400 text-gray-650 d-block'>{aplicacaoInicial.date}</span>
              </S.Card>
            }
            {cardDetailsExist &&
              <S.Card>
                <span className='fs-12 lh-15 fs-xl-16 lh-xl-20 fw-400 f-inter text-gray-650 d-block'>
                  Saldo Mínimo
                </span>
                <span className='fs-18 lh-22 fs-md-20 lh-md-25 fs-xl-32 lh-xl-40 f-sora fw-600 text-gray-750'>{saldoMinimo.value}</span>
                <span className='fs-12 lh-15 fs-xl-14 lh-xl-17 fw-400 text-gray-650 d-block'>{saldoMinimo.date}</span>
              </S.Card>
            }
            {cardDetailsExist &&
              <S.Card>
                <span className='fs-12 lh-15 fs-xl-16 lh-xl-20 fw-400 f-inter text-gray-650 d-block'>
                  Saldo mínimo de permanência
                </span>
                <span className='fs-18 lh-22 fs-md-20 lh-md-25 fs-xl-32 lh-xl-40 f-sora fw-600 text-gray-750'>{saldoMinimoPermanencia.value}</span>
                <span className='fs-12 lh-15 fs-xl-14 lh-xl-17 fw-400 text-gray-650 d-block'>{saldoMinimoPermanencia.date}</span>
              </S.Card>
            }
            {/* {cardDetailsExist &&
              <S.Card>
                <span className='fs-12 lh-15 fs-xl-16 lh-xl-20 fw-400 f-inter text-gray-650 d-block'>
                  Taxa de Administração
                </span>
                <span className='fs-18 lh-22 fs-md-20 lh-md-25 fs-xl-32 lh-xl-40 f-sora fw-600 text-gray-750'>{taxaAdministracao.value}</span>
                <span className='fs-12 lh-15 fs-xl-14 lh-xl-17 fw-400 text-gray-650 d-block'>{taxaAdministracao.date}</span>
              </S.Card>
            } */}
          </div>
        </div>
      </div>
    </S.HeroSection>
  )
}

export default Hero
