/* eslint-disable max-len */
import React from 'react'

import * as S from './style'
import { IUnlistedFoundsSections } from '../../../fundosListados/types'

export interface ISobreOFundo extends IUnlistedFoundsSections {
  data: {
    body: string;
    videoUrl: string;
    mandate?: {
      indexer: string;
      strategy: string;
    };
  };
}

const SobreOFundo = ({ anchor, data }: ISobreOFundo) => {
  return (
    <S.SectionWrapper id={anchor} className='py-5 bg-gray-400'>
      <div className='container'>
        <div className='row'>
          <div className='col-12 col-md-6 mb-5 mb-md-0'>
            <h2 className='fs-24 lh-30 fs-lg-32 lh-lg-40 text-gray-750 f-sora fw-600 mb-4'>
              Sobre o Fundo
            </h2>
            <div className='fs-14 lh-17 fs-lg-16 lh-lg-20 f-inter text-gray-750 fw-400 mb-0' dangerouslySetInnerHTML={{ __html: data.body }} />
            <div className='mt-4'>
              <span className='fs-16 lh-20 fw-600 f-sora text-gray-750 d-block mb-3'>Mandato</span>
              <div className='d-flex justify-content-between'>
                <span className='fs-14 lh-17 fw-700 f-inter text-gray-750 d-block'>Indexador</span><span className='fs-14 lh-17 fw-400 f-inter text-gray-750 d-block'>{data.mandate && data.mandate.indexer}</span>
              </div>
              <hr className='my-2' />
              <div className='d-flex justify-content-between'>
                <span className='fs-14 lh-17 fw-700 f-inter text-gray-750 d-block'>Estratégia</span><span className='fs-14 lh-17 fw-400 f-inter text-gray-750 d-block'>{data.mandate && data.mandate.strategy}</span>
              </div>
            </div>
          </div>
          <div className='col-12 col-md-6 d-md-flex align-items-center'>
            <S.VideoThumb src={data.videoUrl} title='YouTube video player' frameBorder='0' allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share' allowFullScreen />
          </div>
        </div>
      </div>
    </S.SectionWrapper>
  )
}

export default SobreOFundo
