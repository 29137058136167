import styled from 'styled-components'
import { gray } from '../../../../styles/colors'
import breakpoints from '../../../../styles/breakpoints'

export const SectionWrapper = styled.section`
  background-color: ${gray[400]};
  hr {
    border: 1px solid ${gray[550]};
  }
 `

export const VideoThumb = styled.iframe`
  width: 312px;
  height: 158px;

  @media (min-width: ${breakpoints.md}) {
    width: 336px;
    height: 170px;
  }

  @media (min-width: ${breakpoints.lg}) {
    width: 456px;
    height: 232px;
  }

  @media (min-width: ${breakpoints.xl}) {
    width: 560px;
    height: 280px;
  }
`
