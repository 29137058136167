import React from 'react'

import IntercoIcons from '../../../../components/IntercoIcons'
import DefaultCarousel from '../../../../components/UI/Carousels/DefaultCarousel'

import * as S from './style'

import { IVantagens } from '../../../fundosListados/types'

interface IVantagensProps {
  data?: IVantagens[];
}

const Vantagens = ({ data }: IVantagensProps) => {
  return (
    <S.SectionWrapper id='vantagens-de-investir' className='py-5 bg-gray-400'>
      <div className='container'>
        <div className='row'>
          <div className='col-12'>
            <h2 className='fs-24 lh-30 fs-lg-32 lh-lg-40 text-gray-750 f-sora fw-600'>
              Vantagens de investir no Inter Selection Ações
            </h2>
          </div>
          <div className='col-12'>
            {data &&
              <DefaultCarousel
                sm={{ items: 2 }}
                md={{ items: 4 }}
                lg={{ items: 4 }}
                xl={{ items: 4 }}
              >
                {
                  data.map((item: IVantagens) => (
                    <div key={item.key} className='px-1'>
                      <S.Card className='d-flex flex-column p-3'>
                        <div className='mb-3'>
                          <IntercoIcons icon={item.icon} size='MD' color='#FF7A00' />
                        </div>
                        <div>
                          <p className='fs-16 lh-20 f-inter text-gray-750 fw-400 m-0' dangerouslySetInnerHTML={{ __html: item.description }} />
                        </div>
                      </S.Card>
                    </div>
                  ))
                }
              </DefaultCarousel>
            }
          </div>
        </div>
      </div>
    </S.SectionWrapper>
  )
}

export default Vantagens
