/* eslint-disable max-len */
import React from 'react'

import * as S from './style'

interface IDisclaimerProps {
  data: string;
}

const Disclaimer = ({ data }: IDisclaimerProps) => {
  return (
    <S.SectionWrapper id='disclaimer' className='py-5 bg-gray-400'>
      <div className='container'>
        <div className='row'>
          <div className='col-12'>
            <div dangerouslySetInnerHTML={{ __html: data }} />
          </div>
        </div>
      </div>
    </S.SectionWrapper>
  )
}

export default Disclaimer
