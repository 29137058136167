/* eslint-disable max-len */
import React from 'react'

import * as S from './style'

const Newsletter = () => {
  React.useEffect(() => {
    if (window.RDStationForms) {
      new window.RDStationForms('newsletter-site-duplicado-87db4ae21bc9135bac87', 'UA-17915712-21').createForm()
    }
  }, [])

  return (
    <S.SectionWrapper id='newsletter' className='py-5 bg-gray-400'>
      <div className='container'>
        <div className='row'>
          <div className='col-12'>
            <div role='main' id='newsletter-site-duplicado-87db4ae21bc9135bac87' />
          </div>
        </div>
      </div>
    </S.SectionWrapper>
  )
}

export default Newsletter
