/* eslint-disable max-len */
import React from 'react'
import DocumentCard from '../../../../components/DocumentCard'
import * as S from './style'
import { IUnlistedFoundsSections } from '../../../fundosListados/types'
import { IDocuments } from '../../../../types/types'

export interface IDocumentacaoProps extends IUnlistedFoundsSections {
  data?: IDocuments[];
}

const Documentacao = ({ anchor, data }: IDocumentacaoProps) => {
  return (
    <S.SectionWrapper id={anchor} className='py-5 bg-gray-400'>
      <div className='container'>
        <div className='row'>
          <div className='col-12'>
            <h2 className='fs-24 lh-30 fs-lg-32 lh-lg-40 text-gray-750 f-sora fw-600 mb-4'>
              Documentação
            </h2>
          </div>
          <div className='col-12'>
            <div className='documents-listing'>
              {data && data.map(({ nome, data, url }: IDocuments) => (
                <DocumentCard key={url} className='col-12 col-md-6 col-lg-4' info={{ date: data, title: nome, url: url }} />
              ))}
            </div>
          </div>
        </div>
      </div>
    </S.SectionWrapper>
  )
}

export default Documentacao
