/* eslint-disable max-len */
import React from 'react'
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts'

import * as S from './style'
import { IChart, IChartArray, IChartKeyList, ILineData, IListedFoundsSections } from '../../../fundosListados/types'
import slugify from 'slugify'
import { formatToDayMonthYearDate, getMonthAndYear, haveAlphabeticChar, removeDuplicatedObjectFromChartArray, removeUndefinedNameFromChartArray, returnChartKeysList } from '../../../../hooks/utility'
import { rechartsStrokeColors } from '../../../../styles/colors'

interface IRetornoAcumuladoDesdeOInicio extends IListedFoundsSections {
  chartData: {
    lines: ILineData[];
    subtitle: string;
    key: string;
  };
}

const RetornoAcumuladoDesdeOInicio = ({ anchor, chartData }: IRetornoAcumuladoDesdeOInicio) => {
  const [ chart, setChart ] = React.useState<IChart[] | null>(null)
  const [ chartKeyList, setChartKeyList ] = React.useState<IChartKeyList[]>()

  const returnChartData = () => {
    const chart = chartData.lines.reduce((accumulator: IChart[], current: ILineData) => {
      current.data.map((data: IChartArray) => {
        accumulator.push({
          name: data.xAxis,
        })
      })
      return accumulator
    }, [])

    const uniqueArray = removeDuplicatedObjectFromChartArray(chart)

    chartData.lines.forEach((line: ILineData) => {
      line.data.forEach((lineData: IChartArray, indexData: number) => {
        if (uniqueArray[indexData] && uniqueArray[indexData].name) {
          if (lineData.xAxis === uniqueArray[indexData].name) {
            uniqueArray.splice(indexData, 1, {
              ...uniqueArray[indexData],
              [line.name]: parseFloat(lineData.yAxis),
            })
          }
        }
      })
    })

    const keysList: IChartKeyList[] = returnChartKeysList(uniqueArray)
    setChartKeyList(keysList)

    const filteredArray = removeUndefinedNameFromChartArray(uniqueArray)

    formatToDayMonthYearDate(filteredArray)

    return filteredArray
  }

  React.useEffect(() => {
    setChart(returnChartData())
  }, [])

  return (
    <S.SectionWrapper id={anchor} className='py-5 bg-gray-400'>
      <div className='container'>
        <div className='row'>
          <div className='col-12'>
            <h2 className='fs-24 lh-30 fs-lg-32 lh-lg-40 text-gray-750 f-sora fw-600 mb-4'>
              Retorno acumulado desde o ínicio
            </h2>
          </div>
          <div className='col-12' />
          <div className='col-12'>
            {chart && chartKeyList &&
              <ResponsiveContainer width='100%' height={300}>
                <LineChart
                  data={chart}
                  margin={{
                    top: 5,
                    right: 30,
                    left: 20,
                    bottom: 5,
                  }}
                >
                  <CartesianGrid strokeDasharray='3 3' />
                  <XAxis dataKey='name' tickFormatter={(value: string) => haveAlphabeticChar(value) ? getMonthAndYear(value) : value } />
                  <YAxis />
                  <Tooltip />
                  <Legend verticalAlign='top' align='left' iconType='rect' height={60} />
                  {chartKeyList.map((chart: IChartKeyList, index: number) => (
                    <Line key={slugify(String(chart.lineDataKey))} type='monotone' dot={false} dataKey={chart.lineDataKey} strokeWidth={3} stroke={rechartsStrokeColors[index]} />
                  ))}
                </LineChart>
              </ResponsiveContainer>
            }
          </div>
        </div>
      </div>
    </S.SectionWrapper>
  )
}

export default RetornoAcumuladoDesdeOInicio
