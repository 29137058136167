import styled from 'styled-components'
import { gray, orange, white } from '../../../../styles/colors'

import breakpoints from '../../../../styles/breakpoints'

import BackgroundSM from '../../../../images/banners/background-hero-home-inter-asset-sm.png'
import BackgroundMD from '../../../../images/banners/background-hero-home-inter-asset-md.png'
import BackgroundLG from '../../../../images/banners/background-hero-home-inter-asset-lg.png'
import BackgroundXL from '../../../../images/banners/background-hero-home-inter-asset-xl.png'

export const HeroSection = styled.section`
  display: flex;
  align-items: flex-end;
  min-height: 470px;
  background-repeat: no-repeat;
  background-position: top;
  padding-bottom: 50px;
  position: relative;
  background-color: ${gray[400]};

  @media (min-width: ${breakpoints.sm})  and (orientation: landscape) {
    min-height: 100vh;
  }

  @media (min-width: ${breakpoints.md}) {
    min-height: 390px;
    padding-bottom: 60px;
  }

  @media (min-width: ${breakpoints.lg}) {
    min-height: 464px;
  }

  a {
    background-color: ${orange.base};
    outline: none;
    width: 261px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 30px;
    display: block;

    @media (min-width: ${breakpoints.md}) {
      width: 100%;
      max-width: 336px;
      margin-left: 0;
      margin-right: 0;
      margin-bottom: 2px;
      height: 28.25px;
      padding-top: 4px;
    }

    @media (min-width: ${breakpoints.lg}) {
      max-width: 358px;
      margin-bottom: 26px;
      height: 36.61px;
      padding-top: 7px;
    }

    @media (min-width: ${breakpoints.xl}) {
      margin-bottom: 0;
    }

    &:hover {
      background: ${orange.base};
      opacity: 0.9;
    }
  }

  .card-position {
    gap: 12px;
    @media (min-width: ${breakpoints.md}) {
      gap: 24px;
    }
  }

  .header-text-block {
    background-color: rgba(0,0,0, 0.4);

    @media (min-width: ${breakpoints.md}) {
      background-color: rgba(0,0,0, 0.4);
      border-radius: 8px;
      padding: 12px;
    }
  }
`

export const Card = styled.div`
  background-color: ${white};
  border: 1px solid ${gray[550]};
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  border-radius: 8px;
  padding: 10px;
  width: 150px;
  height: 110px;

  .tax-variation {
    color: #00AA4F;
  }

  @media (min-width: ${breakpoints.md}) {
    width: 154px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
  }

  @media (min-width: ${breakpoints.lg}) {
    width: 212px;
  }

  @media (min-width: ${breakpoints.xl}) {
    width: 272px;
  }
`

type BackgroundDiv = {
  backgroundUrl?: {
    url: string;
    name: string;
  };
  $cardDetailsExist?: boolean;
}

export const BackgroundDiv = styled.section<BackgroundDiv>`
  height: ${(props: BackgroundDiv) => props.$cardDetailsExist ? '240px' : '100%'};
  width: 100%;
  background-image: ${(props: BackgroundDiv) => props.backgroundUrl ? `url(${props.backgroundUrl.url})` : `url(${BackgroundSM})`};
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top;
  position: absolute;
  top: 0;

  @media (min-width: ${breakpoints.sm})  and (orientation: landscape) {
    background-size: cover;
  }

  @media (min-width: ${breakpoints.md}) {
    height: ${(props: BackgroundDiv) => props.$cardDetailsExist ? '274px' : '100%'};
    background-size: cover;
    background-image: ${(props: BackgroundDiv) => props.backgroundUrl ? `url(${props.backgroundUrl.url})` : `url(${BackgroundMD})`};
  }

  @media (min-width: ${breakpoints.lg}) {
    height: ${(props: BackgroundDiv) => props.$cardDetailsExist ? '350px' : '100%'};
    background-image: ${(props: BackgroundDiv) => props.backgroundUrl ? `url(${props.backgroundUrl.url})` : `url(${BackgroundLG})`};
  }

  @media (min-width: ${breakpoints.xl}) {
    background-image: ${(props: BackgroundDiv) => props.backgroundUrl ? `url(${props.backgroundUrl.url})` : `url(${BackgroundXL})`};
  }
`
