/* eslint-disable max-len */
import React from 'react'

// Components
import Layout from '../../components/Layout'

import {
  Hero,
  Vantagens,
  SobreOFundo,
  DadosDoFundo,
  RetornoAcumuladoDesdeOInicio,
  Documentacao,
  Newsletter,
  Disclaimer,
} from './_sections/_index'

import { IFound } from '../../types/types'

import Sumary from '../../components/Sumary'

import SEO from '../../components/Seo'
import convertToSlug from '../../helpers/convertToSlug'
import { objectHaveKeys } from '../../hooks/utility'
import AcessoAInformacao from '../../pages/_sections/_acesso-a-informacao'
import OndeComprar from '../fundosListados/_sections/_onde-comprar'
import { IFundos, IHeaderFoundersList, IPageContext, ISumaryData } from '../fundosListados/types'

import disclaimerData from '../../assets/data/disclaimer/data.json'

const Fundos: React.FC<IFundos> = ({ pageContext }: IFundos) => {
  const { fundo, primeiroNome, segundoNome, fundos, charts }: IPageContext = pageContext
  const [ headerFoundsList, setHeaderFoundsList ] = React.useState<IHeaderFoundersList | undefined>()
  const [ sumaryData, setSumaryData ] = React.useState<ISumaryData[]>([])

  const {
    nome,
    descricao,
    about,
    documentacao,
    advantages,
    avisoLegal,
    comments,
    caracteristicasGerais,
    details,
    backgroundImage,
    purchaseOptions,
  }: IFound = fundo

  React.useEffect(() => {
    const createHeaderFoundsList = fundos.reduce((acc: { title: string; anchor: string }[], current: IFound) => {
      acc.push({
        title: current.nome,
        anchor: `/${convertToSlug(`${primeiroNome} ${segundoNome}`, current.nome)}`,
      })

      return acc
    }, [])

    const headerFoundsListCreated = {
      title: `${primeiroNome} ${segundoNome}`,
      anchor: createHeaderFoundsList,
    }

    setHeaderFoundsList(headerFoundsListCreated)
  }, [ ])

  const addToSumaryData = ({ name, anchor }: { name: string; anchor: string }): boolean => {
    setSumaryData((oldArray: ISumaryData[]) => [ ...oldArray, { name, anchor } ])

    return true
  }

  React.useEffect(() => {
    objectHaveKeys(about) && about && addToSumaryData({ name: 'Sobre o fundo', anchor: 'sobre-o-fundo' })
    objectHaveKeys(comments) && caracteristicasGerais && addToSumaryData({ name: 'Dados do fundo', anchor: 'dados-do-fundo' })
    objectHaveKeys(charts) && objectHaveKeys(charts.cumulativeReturn) && addToSumaryData({ name: 'Retorno acumulado', anchor: 'retorno-acumulado' })
    objectHaveKeys(documentacao) && addToSumaryData({ name: 'Documentação', anchor: 'documentacao' })
    console.log('CARATREISTICAS GERAIS_STATUS: ', objectHaveKeys(comments) && caracteristicasGerais)
    console.log('COMMENTS_STATUS: ', objectHaveKeys(comments))
    console.log('CARATREISTICAS_GERAIS: ', caracteristicasGerais)
  }, [ ])

  return (
    <Layout headerFoundsList={headerFoundsList}>
      <SEO title={nome} />
      <Sumary data={sumaryData} />
      <Hero
        data={{ description: descricao, name: segundoNome, nomeFundo: nome }}
        backgroundImage={backgroundImage}
        details={details}
      />
      {objectHaveKeys(advantages) && <Vantagens data={advantages} />}
      {objectHaveKeys(about) && about && <SobreOFundo anchor='sobre-o-fundo' data={about} />}
      {(objectHaveKeys(comments) || caracteristicasGerais) && <DadosDoFundo anchor='dados-do-fundo' data={caracteristicasGerais} comentarios={comments} />}
      {purchaseOptions && <OndeComprar anchor='onde-comprar' data={purchaseOptions} />}
      {objectHaveKeys(charts) && objectHaveKeys(charts.cumulativeReturn) && <RetornoAcumuladoDesdeOInicio anchor='retorno-acumulado' chartData={charts.cumulativeReturn} />}
      {objectHaveKeys(documentacao) && <Documentacao anchor='documentacao' data={documentacao} />}
      <Newsletter />
      <AcessoAInformacao disclaimer={disclaimerData.fundosNaoListados} />
      {avisoLegal && <Disclaimer data={avisoLegal} />}
    </Layout>
  )
}

export default Fundos
