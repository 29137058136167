/* eslint-disable max-len */
import React from 'react'

import * as S from './style'

import { ICard, IUnlistedFoundsSections } from '../../../fundosListados/types'

export interface IDadosDoFundoProps extends IUnlistedFoundsSections {
  data: ICard[];
  comentarios?: string;
}

const DadosDoFundo = ({ anchor, data, comentarios }: IDadosDoFundoProps) => {
  return (
    <S.SectionWrapper id={anchor} className='py-5 bg-gray-400'>
      <div className='container'>
        <div className='row'>
          <div className='col-12'>
            <h2 className='fs-24 lh-30 fs-lg-32 lh-lg-40 text-gray-750 f-sora fw-600 mb-4'>
              Dados do fundo
            </h2>
          </div>
          <div className='col-12'>
            <div className='cards-wrapper'>
              {data.map((card: ICard) => (
                <S.Card key={card.nome}>
                  <span className='fs-10 lh-12 fs-xl-14 lh-xl-17 fw-400 f-inter text-gray-750 mb-2 d-block' dangerouslySetInnerHTML={ { __html: card.nome } } />
                  <p className='fs-12 lh-15 fs-xl-16 lh-xl-20 fw-700 f-inter text-gray-750 mb-0' dangerouslySetInnerHTML={ { __html: card.valor } } />
                </S.Card>
              ))}
            </div>
          </div>
          {comentarios &&
            <div className='col-12 mt-5'>
              <h2 className='fs-24 lh-30 fs-lg-32 lh-lg-40 text-gray-750 f-sora fw-600 mb-4'>
                Comentários
              </h2>
              <div dangerouslySetInnerHTML={{ __html: comentarios }} />
            </div>
          }
        </div>
      </div>
    </S.SectionWrapper>
  )
}

export default DadosDoFundo
